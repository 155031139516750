<template>
    <v-container class="my-2 text-left">
        <v-card>
            <v-toolbar class="indigo" dark flat>
                <v-toolbar-title>Stwórz swoje konto</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form">
                    <v-card-title class="pl-0">
                        Email: {{ email }}
                    </v-card-title>
                    <v-card-title class="pl-0">
                        Stanowisko:
                        {{ role == "copywriter" ? "Copywriter" : "" }}
                        {{ role == "korektor" ? "Korektor" : "" }}
                        {{ role == "praktykant" ? "Praktykant" : "" }}
                    </v-card-title>
                    <v-text-field
                        label="Imię i nazwisko"
                        name="name"
                        type="text"
                        v-model="name"
                        clearable
                        :error-messages="nameErrors"
                        @input="$v.name.$touch()"
                        @blur="$v.name.$touch()"
                    />
                    <v-text-field
                        id="password"
                        label="Hasło"
                        name="password"
                        type="password"
                        v-model="password"
                        :error-messages="passwordErrors"
                        @input="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        clearable
                    />
                    <v-text-field
                        id="phone"
                        label="Numer telefonu"
                        name="phone"
                        type="text"
                        v-model="phone"
                        clearable
                    />
                    <v-combobox
                        v-model="langs"
                        :items="langsArray"
                        label="Języki"
                        multiple
                        chips
                    ></v-combobox>
                    <v-combobox
                        v-model="industries"
                        :items="industriesArray"
                        label="Branże"
                        multiple
                        chips
                    ></v-combobox>
                    <p v-if="feedback">{{ feedback }}</p>
                    <v-checkbox v-model="check">
                        <template v-slot:label>
                            <div>
                                Akceptuję
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a
                                            target="_blank"
                                            href="https://contentwriter.pl/regulamin"
                                            @click.stop
                                            v-on="on"
                                        >
                                            regulamin
                                        </a>
                                    </template>
                                    Otwórz w nowej karcie
                                </v-tooltip>
                                i
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a
                                            target="_blank"
                                            href="https://contentwriter.pl/polityka-prywatnosci"
                                            @click.stop
                                            v-on="on"
                                        >
                                            politykę prywatności
                                        </a>
                                    </template>
                                    Otwórz w nowej karcie
                                </v-tooltip>
                            </div>
                        </template>
                    </v-checkbox>
                    <div class="error" v-if="checkError">{{ checkError }}</div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    class="ma-2"
                    :disabled="$v.$invalid"
                    color="secondary"
                    @click="add"
                    :loading="isLoaded"
                >
                    Stwórz konto
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import { errorMsg } from "@/mixins/Validation";
import io from "socket.io-client";

const token = localStorage.getItem('user');

export default {
    name: "RecruitmentForm",
    mixins: [validationMixin, errorMsg],
    data() {
        return {
            isLoaded: false,
            type: "Osoba Fizyczna",
            loader: null,
            checkError: null,
            email: null,
            account: null,
            password: null,
            feedback: null,
            phone: null,
            sex: null,
            name: null,
            nip: null,
            check: false,
            role: "copywriter",
            socket: io(this.url),
            langs: null,
            industries: null,
            langsArray: [
                "PL",
                "EN",
                "DE",
                "FR",
                "IT",
                "CS",
                "DA",
                "ES",
                "PT",
                "RU",
                "SK",
                "SV",
                "UK",
                "HU",
                "ZH",
                "HR",
                "NL",
                "NB",
            ],
            industriesArray: [
                "Budownictwo",
                "Dietetyka",
                "Edukacja",
                "Erotyka",
                "Farmacja",
                "Finanse/rachunkowość",
                "Fotografia",
                "Fotowoltaika",
                "Fryzjerstwo",
                "Giełda",
                "HR",
                "IT",
                "Inwestycje",
                "Kosmetyka",
                "Kryptowaluty",
                "Lifestyle",
                "Marketing",
                "Meble",
                "Medycyna",
                "Moda",
                "Motoryzacja",
                "Nieruchomości",
                "Prawo",
                "Przemysł",
                "Psychologia",
                "Rolnictwo",
                "Sport",
                "Transport",
                "Turystyka",
                "Wystrój wnętrz",
            ],
            date: new Date().toISOString().substr(0, 7),
        };
    },
    validations: {
        name: {
            required,
        },
        password: {
            required,
        },
    },
    created() {
        this.getData();
        this.socket.on("employeeAddedFromRecruitment", () => {
            this.getData();
        });
    },
    methods: {
        resetValidation() {
            this.$refs.form.reset();
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        getData() {
            this.$axios
                .get(
                    `${this.url}api/users/recruitment/${this.$route.params.id}`, { headers: { 'x-access-token': token }}
                )
                .then((el) => {
                    console.log(el.data.expired);
                    if (!el.data.expired) {
                        this.email = el.data.email;
                        if (el.data.user == "copy") {
                            this.role = "copywriter";
                        } else if (el.data.user == "korektor") {
                            this.role = "korektor";
                        } else if (el.data.user == "praktykant") {
                            this.role = "praktykant";
                        }
                    } else {
                        this.$router.push("/");
                    }
                });
        },
        add() {
            if (!this.$v.$invalid) {
                this.isLoaded = true;
                if (!this.check) {
                    this.checkError = "Prosimy o akceptację.";
                    this.isLoaded = false;
                    return;
                }
                this.$axios
                    .post(`${this.url}api/users/recruitment`, {
                        accountNumber: this.account,
                        password: this.password,
                        role: this.role.toLowerCase(),
                        type: this.type,
                        email: this.email,
                        payment: this.payment,
                        phone: this.phone.replace(/\s/g, ""),
                        langs: this.langs,
                        industries: this.industries,
                        pesel: this.nip,
                        name: this.name,
                        linkId: this.$route.params.id,
                    }, { headers: { 'x-access-token': token }})
                    .then((el) => {
                        this.resetValidation();
                        if (el.data == "ok") {
                            this.$router.push("/");
                        }
                    })
                    .catch(() => {
                        this.isLoaded = false;
                    });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.regulations {
    display: flex;
    align-items: center;
    span {
        margin-right: 4px;
    }
}
</style>
